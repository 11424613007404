.MainWellness {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
}

.MainWellness .section-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-1 h5 {
    display: inline-block;
    background: rgba(74, 196, 243, 0.1);
    padding: 10px 20px;
    border-radius: 7px;
    font-size: 15px;
    font-weight: normal;
    line-height: 1.2;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    text-transform: capitalize;
    text-align: center;
    color: #232323;
}

.section-1 strong {
    width: 60%;
    font-size: 36px;
    text-transform: capitalize;
    font-weight: 500;
    text-align: center;
    text-shadow: 0 0 1px rgba(0, 0, 0, 2);
    line-height: 1.2;
    color: #232323;
}

.section-1 .thumb {
    height: auto;
    text-align: center;
    margin-top: 50px;
}

.thumb img {
    width: 80%;
    margin: auto;
    vertical-align: middle;
    position: relative;
}

.MainWellness .thumb-1 {
    position: absolute;
    z-index: -1;
    top: 10px;
    left: 0;
    bottom: 0;
    width: 100%;
    height: max-content;
}

.MainWellness .thumb-1 img {

    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom center;
}

/**********Section-2**********/

.MainWellness .section-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 10% 0%;
}

.MainWellness .section-2 .heading-2 {
    display: flex;
    flex-direction: column;
    width: 70%;
    align-items: center;
}

.MainWellness .section-2 .heading-2 strong {
    font-size: 36px;
    text-transform: capitalize;
    font-weight: 500;
    text-align: center;
    text-shadow: 0 0 1px rgba(0, 0, 0, 2);
    line-height: 1.2;
    color: #232323;
    margin-bottom: 30px;
    padding: 0% 15%;
}

.MainWellness .section-2 .heading-2 .dev {
    display: flex;
    flex-direction: row;
    gap: 6%;
    margin-bottom: 30px;
}

.MainWellness .section-2 .heading-2 .dev .devider {
    width: 50px;
    height: 2px;
    background: #25285f;
    position: relative;
    z-index: 1;
    left: 10px;
}

.MainWellness .section-2 .heading-2 .dev .devider1 {
    width: 10px;
    height: 2px;
    background: #25285f;
    position: relative;
    z-index: 1;
    left: 10px;
}

.MainWellness .section-2 .heading-2 p {
    margin: 0;
    margin-top: 10px;
    color: #666666;
    margin: 0 0 15px;
    text-transform: none;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    font-size: 15px;
}

.section-2 .Cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 80%;
    padding-top: 5%;
    row-gap: 5%;
}


.section-2 .Cards .Cardsmain {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    line-height: 12px;
    text-align: center;
    width: 90%;
}

.section-2 .Cards .Cardsmain .CardsImg .second {
    position: absolute;
    left: 50%;
    top: -17px;
    content: "";
    height: 100%;
    width: 55%;
    line-height: 100%;
    background: linear-gradient(62.5deg, transparent, rgba(237, 245, 255, 0.9));
    border-radius: 50%;
    margin-left: -25px;
    z-index: -10;
}

.section-2 .Cards .Cardsmain .CardsImg {
    position: relative;
    z-index: 200;
}

.section-2 .Cards .Cardsmain .CardsImg img {
    display: inline-flex;
    height: auto;
    width: 38%;
    margin: auto;
    PADDING: 13PX;
    border: none;
    outline: none;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}

.section-2 .Cards .Cardsmain h4 {
    text-transform: capitalize;
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 15px;
    color: #232323;
    line-height: 1.2;
    letter-spacing: 0;
    margin-bottom: 15px;
}

.section-2 .Cards .Cardsmain p {
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
    text-align: justify;
    font-size: 14px;
    color: #858484;
    text-transform: none;
    font-weight: 400;
    box-sizing: border-box;
    line-height: 30px;
}

/***********section-3***********/
.MainWellness .section-3 {
    position: relative;
    background-color: #25285f;
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}

.MainWellness .section-3 .container-3 {
    margin: 6% 10%;
    width: 80%;
    color: #fff;
    display: flex;
    flex-direction: row;
}

.section-3 .container-3 .left {
    width: 50%;
}

.section-3 .container-3 .left strong {
    display: flex;
    font-weight: 700;
    font-size: 1.8rem;
    text-shadow: 0 0 1px rgba(0, 0, 0, 2);
    margin-bottom: 15px;
    letter-spacing: 0.5px;
}

.section-3 .container-3 .left p {
    margin: 0 0 15px;
    text-transform: none;
    font-weight: 400;
    padding-left: 0%;
    font-size: 15px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 5);
}

.section-3 .container-3 .right {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-3 .right .button {
    color: #2a2d63;
    background: #fff;
    padding: 10px 30px;
    display: inline-block;
    line-height: 25px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 5);
    text-transform: capitalize;
    overflow: hidden;
    border-radius: 5px;
    font-size: 17px;
    cursor: pointer;
}

@media (min-width: 300px) and (max-width: 500px) {
    .MainWellness .section-3 .container-3 {
        display: flex;
        flex-direction: column;
    }

    .section-3 .container-3 .right {
        width: 100%;
    }

    .section-3 .container-3 .left {
        width: 100%;
    }
}


/********section-4*********/
.MainWellness .section-4 {
    display: flex;
    flex-direction: row;
    margin: 0% 9%;
    margin-bottom: 5%;
}

.section-4 .left {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.section-4 .left h5 {
    font-weight: 600;
    text-transform: uppercase;
    color: #25285f;
    margin-bottom: 15px;
    font-size: 1.15rem;
}

.section-4 .left h2 {
    font-weight: normal;
    text-shadow: 0 0 1px rgba(0, 0, 0, 5);
    margin-bottom: 30px;
    font-size: 40px;
    line-height: 1.2;
    color: #232323;
    letter-spacing: 0;
    margin: 0;
    margin-bottom: 15px;
}

.section-4 .left ul {
    display: flex;
    flex-direction: column;
    padding: 0;
}

.section-4 .left ul p {
    color: #666666;
    text-transform: none;
    font-weight: 400;
    font-size: 15px;
    margin-left: 5.6%;
}

.section-4 .left ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.section-4 .left ul li h5 {
    font-weight: normal;
    color: #232323;
    text-shadow: 0 0 1px rgba(0, 0, 0, 5);
    text-transform: none !important;
    margin: 0;
}
.section-4 .right {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
}
.section-4 .right img{
    width: 100%;
    padding-top: 10%;
}

@media (min-width: 300px) and (max-width: 800px) {
    .MainWellness .section-4 {
        display: flex;
        flex-direction: column;
        margin: 0% 5%;
    }
    .section-4 .left {
        width: 100%;
    }
    .section-4 .right {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}



/*********Section-6*********/
.MainWellness .section-6{
    width: 100%;
    text-transform: none;
    margin-bottom: 5%;
}