.MainHome {
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;
}

/****************** Section-1 *****************/

.MainHome .Home-1 {
    width: 100%;
    height: max-content;
    justify-content: center;
    background: #25285f;
}

.MainHome .Home-1 .Home {
    display: flex;
    flex-direction: row;
    margin: 6% 6%;
}

.MainHome .Home-1 .Home .left {
    display: flex;
    flex-direction: column;
    width: 50%;
    color: #fff;
    text-align: center;
    gap: 2%;
}

.left b {
    letter-spacing: 1px;
    font-size: 36px;
}

.left p {
    width: 95%;
    text-transform: none !important;
    color: rgb(255, 255, 255);
    font-size: 17px;
    letter-spacing: 0.5px;
    line-height: 30px;
    padding-left: 3%;
}

.left .Endbuttons {
    display: flex;
    flex-direction: row;
    gap: 10%;
    padding-left: 4%;
    padding-top: 10%;
}

.Endbuttons .button1 {
    display: flex;
    flex-direction: row;
    width: 50%;
    background: #fff;
    align-items: center;
}

.button1 .button-1-1 {
    display: flex;
    flex-direction: column;
    gap: 1%;
    margin: 1% 2%;
}

.button1 .button-1-1 p {
    color: #6a797e;
    display: block;
    font-size: 11px;
    letter-spacing: 1px;
    margin: 0 0 0px;
    text-transform: uppercase;
    white-space: nowrap;
}

.button1 .button-1-1 img {
    height: 23px;
    width: 82px;
}

.button1 .button-1-2 {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.button1 .button-1-2 p {
    color: #6a797e;
    display: inline-block;
    font-size: 14px;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
    white-space: nowrap;
}

.Endbuttons .button2 {
    background: #fff;
    width: 40%;
    color: black;
    text-align: left;
}

.button2 .button-2-1 {
    display: flex;
    flex-direction: column;
}

.button2 .button-2-1 img {
    height: 22px;
    width: 139px;
}

.MainHome .Home-1 .Home .right {
    width: 50%;
    justify-content: center;
    align-items: center;
}

.right img {
    width: 90%;
}

@media (max-width: 900px) {
    .MainHome .Home-1 {
        padding-top: 10%;
    }
    .MainHome .Home-1 .Home .left {
        width: 100%;
    }

    .left .Endbuttons {
        display: flex;
        flex-direction: column;
    }

    .MainHome .Home-1 .Home .right {
        width: 100%;
        justify-content: center;
    }

    .right img {
        padding-top: 10%;
        width: 100%;
    }

    .MainHome .Home-1 .Home {
        flex-direction: column;
        margin: 6%;
    }
}



/**********Section-2***********/

.MainHome .Home-2 {
    padding-top: 11%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.MainHome .Home-2 .Field {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 56%;
    gap: 30px;
}

.MainHome .Home-2 .Field .dev {
    display: flex;
    flex-direction: row;
    gap: 6%;
}

.MainHome .Home-2 .Field .dev .devider {
    width: 50px;
    height: 2px;
    background: #25285f;
    position: relative;
    z-index: 1;
    left: 10px;
}

.MainHome .Home-2 .Field .dev .devider1 {
    width: 10px;
    height: 2px;
    background: #25285f;
    position: relative;
    z-index: 1;
    left: 10px;
}

.MainHome .Home-2 .Field strong {
    display: block;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 40px;
    color: #232323;
}

.MainHome .Home-2 .Field p {
    color: #666666;
    margin: 0 0 15px;
    text-transform: none !important;
    font-weight: 400;
    font-size: 15px;
    line-height: 33px;
}

.MainHome .Cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    width: 80%;
    gap: 4%;
    column-gap: 3%;
    padding-top: 5%;
}

.MainHome .Cards .Cardsmain {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    line-height: 12px;
    text-align: center;
    width: 100%;
    border-bottom: 3px solid;
    box-shadow: 0 5px 30px 0 rgba(214, 215, 216, 0.5);
}

.MainHome .Cards .Cardsmain .CardsImg {
    margin: 10% 0%;
    border: 10px solid #b2b7e7;
    background: linear-gradient(45deg, #24285f 0%, #24285f 100%);
    display: flex;
    font-size: 40px;
    height: 80px;
    width: 80px;
    line-height: 80px;
    color: #ffffff;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    margin-bottom: 30px;
    position: static;
    z-index: 1;
}

.MainHome .Cards .Cardsmain .CardsImg img {
    display: inline-flex;
    height: auto;
    width: 60%;
    margin: auto;
    PADDING: 13PX;
    border: none;
    outline: none;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}

.MainHome .Cards .Cardsmain .CardsImg1 {
    margin: 10% 0%;
    border: 10px solid #fccbda;
    background: linear-gradient(45deg, #c36f8a 0%, #c36f8a 100%);
    display: flex;
    font-size: 40px;
    height: 80px;
    width: 80px;
    line-height: 80px;
    color: #ffffff;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    margin-bottom: 30px;
    position: static;
    z-index: 1;
}

.MainHome .Cards .Cardsmain .CardsImg1 img {
    display: inline-flex;
    height: auto;
    width: 60%;
    margin: auto;
    PADDING: 13PX;
    border: none;
    outline: none;
    max-width: 100%;
    vertical-align: middle;
    border-style: none;
}

.MainHome .Cards .Cardsmain h4 {
    text-shadow: 0 0 1px rgba(0, 0, 0, 2);
    width: 80%;
    font-weight: bolder;
    color: #232323;
    font-weight: normal;
    line-height: 1.2;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 0;
    margin: 0;
    font-size: 1.5rem;
}

.MainHome .Cards .Cardsmain p {
    width: 82%;
    color: #666666;
    margin: 0 0 15px;
    text-transform: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    margin: 5% 0px;
}

@media (min-width: 300px) and (max-width: 500px){
    .MainHome .Home-2{
        padding-bottom: 115%;
    }
}
@media (min-width: 501px) and (max-width: 900px){
    .MainHome .Home-2{
        padding-bottom: 57%;
    }
}


/**********Section-3***********/
.MainHome .Cards3 {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    width: 80%;
    gap: 4%;
    column-gap: 3%;
    padding-top: 5%;
}

.MainHome .Cards3 .Cardsmain {
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 12px;
    width: 100%;
}

.MainHome .Cards3 .Cardsmain img {
    width: 30%;
}

.MainHome .Cards3 .Cardsmain h4 {
    text-align: center;
    text-shadow: 0 0 1px rgba(0, 0, 0, 2);
    width: 100%;
    font-weight: bolder;
    color: #232323;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0;
    margin: 7%;
    font-size: 1.5rem;
}

.MainHome .Cards3 .Cardsmain p {
    width: 100%;
    color: #666666;
    margin: 0 0 15px;
    text-transform: none;
    text-align: justify;
    word-spacing: 1px;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
}

/**********Section-4***********/


/**********Section-5***********/
.MainHome .Home-5 {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.MainHome .Home-5 .Maincomp {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 85%;
    margin: 5%;
}

.MainHome .Home-5 .Maincomp .left {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.MainHome .Home-5 .Maincomp .left .up {
    display: flex;
    flex-direction: column;
}

.MainHome .Home-5 .Maincomp .left .up h6 {
    font-size: 1.20rem;
    color: #25285f;
    margin: 3% 0%;
}

.MainHome .Home-5 .Maincomp .left .up strong {
    width: 50%;
    font-weight: 700;
    font-size: 40px;
    line-height: 1.2;
    color: #232323;
}

.MainHome .Home-5 .Maincomp .left .up p {
    padding: 2% 0%;
    font-size: 15px;
    color: #666666;
    text-transform: none;
    font-weight: 400;
}

.MainHome .Home-5 .Maincomp .left .up .button {
    background: #25285f;
    color: #ffffff;
    width: 20%;
    text-align: center;
    padding: 10px 30px;
    display: inline-block;
    line-height: 25px;
    font-size: 17px;
    cursor: pointer;
    border-radius: 5px;
}

.MainHome .Home-5 .Maincomp .left .down {
    display: flex;
    flex-direction: column;
}

.MainHome .Home-5 .Maincomp .left .down h3 {
    font-weight: 600;
    color: #232323;
    font-weight: normal;
    line-height: 1.2;
    font-size: 1.75rem;
    letter-spacing: 0;
    margin: 0;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.MainHome .Home-5 .Maincomp .left .down ul li {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #fff;
    margin: 0;
    padding: 0;
}

.MainHome .Home-5 .Maincomp .left .down ul {
    margin: 0;
    padding: 0;
}

.MainHome .Home-5 .Maincomp .left .down ul li CheckOutlinedIcon {
    background: #25285f;
}

.MainHome .Home-5 .Maincomp .left .down ul li h5 {
    margin: 2% 1%;
    width: 90%;
    font-weight: normal;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.6);
    position: relative;
    z-index: 1;
    color: #232323;
    font-size: 1.25rem;
    line-height: 1.2;
    letter-spacing: 0;
}

.MainHome .Home-5 .Maincomp .right {
    width: 50%;
    display: flex;
    justify-content: left;
    padding-top: 5%;
}

.MainHome .Home-5 .Maincomp .right .wrap {
    margin-left: 10%;
}

.MainHome .Home-5 .Maincomp .right .cards {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
}

.MainHome .Home-5 .Maincomp .right .cards .card1 {
    background: #25285f;
    padding-top: 10%;
    padding-left: 10%;
}

.MainHome .Home-5 .Maincomp .right .cards .card1 img {
    width: 65px;
    margin-bottom: 15px;
    border: none;
    outline: none;
    max-width: 100%;
}

.MainHome .Home-5 .Maincomp .right .cards .card1 h4 {
    color: #fff;
    font-weight: normal;
    margin-bottom: 15px;
    text-transform: capitalize;
    line-height: 1.2;
    letter-spacing: 0;
    margin: 0;
    font-size: 1.5rem;
}

.MainHome .Home-5 .Maincomp .right .cards .card1 p {
    text-transform: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #ffffff;
}

.MainHome .Home-5 .Maincomp .right .cards .card2 {
    background: #ffffff;
    padding-top: 10%;
    padding-left: 10%;
    box-shadow: 0 0 10px #cccccc;
}

.MainHome .Home-5 .Maincomp .right .cards .card2 img {
    width: 65px;
    margin-bottom: 15px;
    border: none;
    outline: none;
    max-width: 100%;
}

.MainHome .Home-5 .Maincomp .right .cards .card2 h4 {
    color: #232323;
    font-weight: normal;
    margin-bottom: 15px;
    text-transform: capitalize;
    line-height: 1.2;
    letter-spacing: 0;
    margin: 0;
    font-size: 1.5rem;
}

.MainHome .Home-5 .Maincomp .right .cards .card2 p {
    text-transform: none;
    width: 90%;
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 30px;
    color: #666666;
    ;
}

/* Media Queries */
@media (min-width: 769px) and (max-width: 1145px) {
    .MainHome .Home-5 .Maincomp .left .up .button {
        width: 30%;
    }
}

@media (max-width: 768px) {
    .MainHome .Home-5 .Maincomp {
        flex-direction: column;
        width: 100%;
    }

    .MainHome .Home-5 .Maincomp .left .up .button {
        width: 25%;
    }

    .MainHome .Home-5 .Maincomp .left,
    .MainHome .Home-5 .Maincomp .right {
        width: 100%;
    }

    .MainHome .Home-5 .Maincomp .right .wrap {
        margin-left: 0;
    }
}

@media (max-width: 480px) {
    .MainHome .Home-5 .Maincomp .left .up strong {
        font-size: 32px;
    }

    .MainHome .Home-5 .Maincomp .left .up p {
        font-size: 14px;
    }

    .MainHome .Home-5 .Maincomp .left .up .button {
        font-size: 16px;
    }

    .MainHome .Home-5 .Maincomp .left .down h3 {
        font-size: 1.5rem;
    }

    .MainHome .Home-5 .Maincomp .left .down ul li h5 {
        font-size: 1rem;
    }

    .MainHome .Home-5 .Maincomp .right .cards .card1 h4,
    .MainHome .Home-5 .Maincomp .right .cards .card2 h4 {
        font-size: 1.25rem;
    }

    .MainHome .Home-5 .Maincomp .right .cards .card1 p,
    .MainHome .Home-5 .Maincomp .right .cards .card2 p {
        font-size: 14px;
    }

    .MainHome .Home-5 .Maincomp .right .cards {
        display: grid;
        grid-template-columns: auto;
    }
}

/**********Section-6***********/
.MainHome .Home-6 {
    width: 100%;
    background-color: #aeaeae0d;
    display: flex;
    justify-content: center;
}
.MainHome .Home-6 .wrapp{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5%;
}
.MainHome .Home-6 .wrapp .cards{
    width: 24%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.MainHome .Home-6 .wrapp .cards img{
    width: 80px;
    margin-bottom: 10%;
}
.MainHome .Home-6 .wrapp .cards strong{
    color: #000;
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.MainHome .Home-6 .wrapp .cards p{
    font-weight: normal;
    line-height: 1.2;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 0;
    font-size: 1.75rem;
}
/* Responsive Styles */

@media (min-width: 481px) and (max-width: 1100px) {
    .MainHome .Home-6 .wrapp {
        flex-wrap: wrap;
    }

    .MainHome .Home-6 .wrapp .cards {
        width: 45%;
        margin-bottom: 5%;
    }

    .MainHome .Home-6 .wrapp .cards img {
        width: 60px;
    }

    .MainHome .Home-6 .wrapp .cards strong {
        font-size: 28px;
    }

    .MainHome .Home-6 .wrapp .cards p {
        font-size: 1.5rem;
    }
}
@media(min-width: 300px) and (max-width: 480px) {
    .MainHome .Home-6 .wrapp {
        flex-wrap: wrap;
    }

    .MainHome .Home-6 .wrapp .cards {
        width: 90%;
        margin-bottom: 5%;
    }

    .MainHome .Home-6 .wrapp .cards img {
        width: 60px;
    }

    .MainHome .Home-6 .wrapp .cards strong {
        font-size: 28px;
    }

    .MainHome .Home-6 .wrapp .cards p {
        font-size: 1.5rem;
    }
}


/****************** Section-7 *****************/

.Home-2 .maincompo {
    width: 100%;
    margin: 2%;
  }
  
  .Home-2 .maincompo .card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 5%;
    justify-content: center;
  }
  .Home-2 .maincompo .card .card-1 {
    position: relative;
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .Home-2 .maincompo .card .card-1 img {
    background: #fff;
    border-radius: 20px;
    color: #ffffff;
    font-size: 0px;
    height: 65px;
    line-height: 120px;
    text-align: center;
    width: 65px;
    padding: 22px;
    display: inline-block;
    border: 1px solid #ffffff;
    box-shadow: 0 0 10px #cccccc;
    margin-bottom: 15px;
    display: block;
  }
  
  .Home-2 .maincompo .card .card-1 strong {
    text-align: center;
    text-shadow: 0 0 1px rgba(0, 0, 0, 2);
    width: 100%;
    font-weight: bolder;
    color: #232323;
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: 0;
    margin: 7%;
    font-size: 1.5rem;
    text-align: center;
  }
  
  .Home-2 .maincompo .card .card-1 p {
    width: 100%;
    color: #666666;
    margin: 0 0 15px;
    text-transform: none;
    text-align: justify;
    word-spacing: 1px;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    letter-spacing: 1px;
  }
  .Home-2 .maincompo .card .card-1 span{
    color: #ffffff;
    right: 84px;
    top: -13px;
    height: 40px;
    line-height: 40px;
    width: 40px;
    z-index: 1;
    background: #25285f;
    font-weight: 800;
    font-size: 14px;
    border-radius: 50%;
    box-shadow: 0 0 10px #cccccc;
    text-align: center;
    position: absolute;
  }
  
  @media (min-width: 768px) {
    .Home-2 .maincompo .card .card-1 {
      width: 48%;
      max-width: 300px;
    }
  }
  
  @media (min-width: 992px) {
    .Home-2 .maincompo .card .card-1 {
      width: 30%;
      max-width: 300px;
    }
  }  


  /****************** Section-8 *****************/
  .Home-8{
    width: 100%;
  }