.MainHome .Home-slider {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.MainHome .Home-slider-1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-size: contain;
    background-position: center bottom;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
}

.MainHome .Home-slider .Field {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 100%;
}

.MainHome .Home-slider .Field .dev {
    display: flex;
    flex-direction: row;
    gap: 6%;
}

.MainHome .Home-slider .Field .dev .devider {
    width: 50px;
    height: 2px;
    background: #25285f;
    position: relative;
    z-index: 1;
    left: 10px;
}

.MainHome .Home-slider .Field .dev .devider1 {
    width: 10px;
    height: 2px;
    background: #25285f;
    position: relative;
    z-index: 1;
    left: 10px;
}

.MainHome .Home-slider  strong {
    display: block;
    font-weight: 700;
    position: relative;
    margin-bottom: 0;
    line-height: 1.4;
    font-size: 40px;
    color: #232323;
    margin-bottom: 30px;
}

.MainHome .Home-slider .Slider {
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5%;
}

.MainHome .Home-slider .Slider .Slides {
    width: 100%;
    display: flex;
    transition: transform 1s ease; /* Updated transition duration to 0.5s */
  }

  .MainHome .Home-slider .Slider .Slide {
    width: 100%;
    border: 1px solid #e7e7e7;
    padding: 8% 4%;
    text-align: center;
    display: none;
    transition: opacity 1s ease; /* Added transition for opacity */
  }

.MainHome .Home-slider .Slider .Slide p {
    font-size: 16px;
    line-height: 35px;
    font-style: italic;
    font-weight: 700;
    color: #666666;
    margin: 0 0 15px;
    text-transform: none;
}

.MainHome .Home-slider .Slider .Slide strong {
    text-shadow: 0 0 1.5px rgba(0, 0, 0, 1);
    font-weight: 525;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 20px;
    color: #232323;
    line-height: 1.2;
    font-family: 'Nunito', sans-serif;
    letter-spacing: 0;
    margin: 0;
}

.MainHome .Home-slider .Slider .Slide.active {
    display: block;
    opacity: 1; /* Ensure active slide is fully visible */
  }

.MainHome .Home-slider .Bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.MainHome .Home-slider .Bullets .Bullet {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #ccc;
    margin: 0 5px;
    cursor: pointer;
}

.MainHome .Home-slider .Bullets .Bullet.active {
    width: 15px;
    height: 15px;
    background-color: #000000;
}

@media (max-width: 768px) {
    Home-slider-1{
      background-image: none !important;
    }
    .MainHome .Home-slider .Field {
        width: 100%;
    }
    .MainHome .Home-slider .Slider {
        width: 95%;
    }
  }