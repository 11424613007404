@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');


.heading{
    font-size: 3rem;
    font-family: "Nunito", sans-serif;
    font-weight: 1000;
    color: #25258f;
    margin-left: 10%;
    margin-top: 10vh;
    @media screen and (max-width: 991px) {
        margin-left: 10%;
        margin-top: 15vh;
      }

}

.container{
    width: 80%;
    display: flex;
    margin-left: 10%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    @media screen and (max-width: 991px) {
        margin-left: 10%;
        display: flex;
        flex-direction: column;
      }
    .section{
        width: 100%;
        display: flex;
        @media screen and (max-width: 991px) {
            width: 100%;
          }
        img{
            width: 100%;
        }
        
    }
    .section2{
        width: 50%;
        @media screen and (max-width: 991px) {
            width: 100%;
          }

          a{
            text-decoration: none;  
          }

        .h1{
            font-size: 1.5rem;
            font-weight: 1000;
            font-family: 'Trebuchet MS', sans-serif;
            color: black;
        }

        .para{
             font-size: 1rem;
             font-family: "Nunito", sans-serif;
             font-weight: 400;

        }
    }
}




// /* Blog.module.scss */
// .heading {
//     font-size: 24px;
//     font-weight: bold;
//     margin-bottom: 20px;
//   }
  
//   .container {
//     display: flex;
//     margin-bottom: 20px;
  
//     .section {
//       flex: 1;
//       margin-right: 20px;
  
//       img {
//         width: 30%;
//         height: auto;
//         margin-bottom: 10px;
//       }
  
//       a {
//         color: #007bff;
//         text-decoration: none;
//       }
  
//       .h1 {
//         font-size: 18px;
//         margin-bottom: 10px;
//       }
  
//       .para {
//         font-size: 14px;
//         line-height: 1.5;
//       }
//     }
//   }
  
//   .pagination {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     margin-top: 20px;
  
//     button {
//       margin: 0 5px;
//       padding: 8px 12px;
//       background-color: #f2f2f2;
//       border: none;
//       border-radius: 4px;
//       cursor: pointer;
  
//       &:disabled {
//         opacity: 0.6;
//         cursor: not-allowed;
//       }
  
//       &.active {
//         background-color: #007bff;
//         color: #fff;
//       }
//     }
//   }
  