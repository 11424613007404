@import url('https://fonts.googleapis.com/css2?family=Nunitol:wght@300&display=swap');

  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-icon {
    position: relative;
    transform: translateY(-50%);
    color: #999;
    top:45px;
    left: 1vw;
    z-index: 1;
  }
  .form-icon2{
    position: relative;
    transform: translateY(-50%);
    color: #999;
    top:45px;
    left: 2vw;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #25285f;
    border-radius: 4px;
    height: 4vh;
    outline: none   ;
  }
  .form-input2 {
    width: 95%;
    margin-left: 5%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #25285f;
    border-radius: 4px;
    height: 4vh;
    outline: none   ;
  }
  .form-input4 {
    width: 90%;
    margin-right: 5%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #25285f;
    border-radius: 4px;
    height: 4vh;
    outline: none   ;
  }
  .form-input3 {
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #25285f;
    border-radius: 4px;
    height: 8vh;
    outline: none   ;
  }
  
  
  .error {
    color: red;
    font-family: 'Nunito', sans-serif;    

  }
  
  .submit-b {
    margin-top: 5vh;
    padding: 10px 20px;
    color: #25285f;
    border: solid 1px #25285f;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;    

  }
  .submit-b:hover {
    margin-top: 5vh;
    padding: 10px 20px;
    color: white;
    border: solid 1px #25285f;
    background: #25285f;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;    

  }
  
  @media screen and (max-width: 480px) {
    
    .form-icon {
      left: 15px;
    }
  }
  
.c-f-flex-div{
    display: flex;
    column-gap: 2vw;
}
.contact-flex-item{
    width: 50%;
}
.contact-form{
    margin-top: 7vh;
}

@media screen and (max-width:991px) {
    .c-f-flex-div{
        display: flex;
        flex-direction: column;
    }
    .contact-flex-item{
        width: 100%;
    }
    .contact-form{
      margin-top: 7vh;
      margin-left: 10%;
      margin-right: 10%;
  }
  .form-input2 {
    width: 100%;
    margin-left: 0%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #25285f;
    border-radius: 4px;
    height: 4vh;
    outline: none   ;
  }
  .form-input4 {
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    border: 1px solid #25285f;
    border-radius: 4px;
    height: 4vh;
    outline: none   ;
  }
}