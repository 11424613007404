/* @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunitol:wght@300&display=swap"); */
.contact-first {
  width: 100%;
  height: 60vh;
  background: #f9f9f9;
  display: flex;
}
.contact-first-left {
  width: 40%;
  height: 50vh;
  background: #f9f9f9;
  margin-left: 10%;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.contact-first-right {
  width: 40%;
  height: 50vh;
  background: #f9f9f9;
  margin-right: 10%;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.contact-img-1 {
  margin-left: 10%;
  margin-top: 10%;
  width: 80%;
}
.contact-heading {
  margin-top: 20%;
  font-family: "Nunito", sans-serif;
  color: #25285f;
  font-size: 2.25rem;
  font-weight: bolder;
}
.contact-text {
  font-family: "Nunitol", sans-serif;
  color: #666666;
  font-weight: lighter;
  letter-spacing: 1px;
  font-size: 1rem;
  line-height: 1.6;
}
.contact-third {
  width: 100%;
  display: flex;
  margin-bottom: 2vh;
  margin-top: 15vh;
}
.contact-third-left {
  width: 40%;
  margin-left: 10%;
  align-items: left;
  text-align: left;
  justify-content: center;
}
.contact-third-right {
  width: 40%;
  margin-right: 10%;
  padding-left: 5%;
}
.testimotionals {
  width: 25vw;
  display: inline-block;
  margin-left: 80px;
  margin-top: 50px;
}
.testimotionals .card {
  border-radius: 10px;
  height: 40vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  box-shadow: 0 0 10px #e7e7e7;
}

.testimotionals .card .layer {
  z-index: 1;
  position: absolute;
  top: calc(100% - 2px);
  height: 100%;
  width: 100%;
  left: 0;
  background: #25285f;
  transition: 0.5s;
}

.testimotionals .card .content {
  z-index: 2;
  position: relative;
}

.testimotionals .card:hover .layer {
  top: 0;
}

.testimotionals .card .content .details h2 {
  font-size: 18px;
  color: black;
}
.testimotionals .card:hover .content .details h2 {
  color: white;
  position: relative;
}
.testimotionals .card:hover .content .details .card-icon {
  color: white;
  position: relative;
}
.testimotionals .card .content .details h2 span {
  font-size: 18px;
  color: #25285f;
  transition: 0.5s;
  margin-top: 20px;
}
.testimotionals .card:hover .content .details h2 span {
  color: white;
  position: relative;
}

@media screen and (max-width: 991px) {
  .contact-first {
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    width: 80%;
  }
  .contact-first-left {
    width: 80%;
    height: max-content;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .contact-first-right {
    margin-top: 0vh;
    width: 100%;
    height: 50vh;
    background: #f9f9f9;
  }
  .contact-img-1 {
    margin-left: 0%;
    margin-top: 0%;
    width: 100%;
  }
  .contact-second-main {
    display: flex;
    flex-direction: column;
  }
  .contact-third {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 2vh;
    margin-top: 15vh;
  }
  .contact-third-left {
    width: 80%;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  .contact-third-right {
    margin-top: 0vh;
    width: 80%;
    padding-left: 0%;
  }
  .contact-cards-div {
    display: flex;
    flex-direction: column;
  }
  .testimotionals {
    width: 80%;
    display: inline-block;
    margin-left: 10%;
    margin-top: 50px;
  }
  .testimotionals .card {
    border-radius: 10px;
    height: 50vh;
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    text-align: center;
    box-shadow: 0 0 10px #e7e7e7;
  }
}

@media screen and (max-width: 500px) {
  .contact-first {
    margin-left: 10%;
    height: 100vh;
  }
}
@media (min-width: 501px) and (max-width: 990px) {
  .contact-first {
    margin-left: 10%;
    height: 120vh;
  }
}

.contact-cards-div {
  display: flex;
}

.card-icon {
  font-size: 5rem;
  color: #25285f;
}
