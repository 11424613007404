.mainpage{
    display: flex;
    flex-direction: column;
    height: max-content;
}
.header{
    position: fixed;
    width:100%;
    z-index: 1000;
}
.MainPage{
    padding-top: 6%;
}