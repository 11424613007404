@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');
.container{
    margin-top: 5vh;
    width: 100%;
    display: flex;
    @media screen and (max-width: 991px) {
        width: 100%;
        margin-left: 0%;
        display: flex;
        flex-direction: column;  
       
      }
    .section1{
        margin-left: 10%;
        width: 40%;
        text-align-last: left;
        @media screen and (max-width: 991px) {
            width: 80%;
            margin-left: 10%;
      
          }

        .title{
            font-weight: bolder;
            font-size: 1.25rem;
            color: #25285f;
        }
        .heading{
            margin-top: 2vh;
            font-size: 2.5rem;
            color: #666666;
            font-family: 'Nunito', sans-serif;
            font-weight: 1000;

        }
        .text{
            margin-top: 3vh;
            line-height: 1.5;
            color: #666666;
            word-spacing: 1px;
            font-weight: lighter;
            font-family: 'Nunito', sans-serif;
        }
    }
    .section2{
        width: 40%;
        margin-right: 0%;
        margin-bottom: 10vh;
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-left: 0%;
            margin-top: 10vh;
            margin-bottom: 5vh;
      
          }
        .img{
            width: 100%;
            @media screen and (max-width: 991px) {
                width: 100%;
        
              }
        }
    }
}