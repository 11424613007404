.Home-4 {
    width: 100%;
    display: flex;
    height: max-content;
    flex-direction: column;
    background: #25285f12;
    align-items: center;
}

.Home-4 .border {
    display: flex;
    justify-content: center;
    width: 80%;
    border: 1px solid black;
    border-radius: 196px;
    margin: 5% 5%;
}

.Home-4 .border .middle {
    display: flex;
    flex-direction: column;
    margin: 3% 3%;
    text-align: center;
    align-items: center;
    column-gap: 10%;
}

.Home-4 .border .middle p {
    font-weight: 600;
    color: #25285f;
    position: relative;
    z-index: 2;
}

.Home-4 .border .middle strong {
    display: flex;
    width: 65%;
    color: #000;
    z-index: 2;
    font-size: 50px;
    letter-spacing: -2px;
    line-height: 60px;
    font-weight: lighter;
    margin-bottom: 4%;
}

.Home-4 .border .middle .button {
    padding-top: 10%;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    background-color: transparent;
    color: #000;
    border: 1px solid #1A3439;
    border-radius: 5px;
    padding: 10px 35px;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 1px;
}

.Home-4 .border .middle .button:hover {
    background: #25285f;
    color: #ffffff;
    font-weight: 700;
}

.Home-4 .icons {
    position: relative;
}

.Home-4 .icons .integrationbox {
    position: absolute;
    width: 100px;
    height: 100px;
    font-size: 50px;
    text-align: center;
    line-height: 100px;
    background: #fff;
    color: #ffffff;
    z-index: 1;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
    border: 1px solid #3e3e3e;
}

.Home-4 .icons .integrationbox img {
    width: 50px;
}

.Home-4 .icons .integrationbox:nth-child(1) {
    top: -415px;
    left: -480px;
}

.Home-4 .icons .integrationbox:nth-child(2) {
    top: -280px;
    left: -590px;
}

.Home-4 .icons .integrationbox:nth-child(3) {
    top: -150px;
    left: -480px;
}

.Home-4 .icons .integrationbox:nth-child(4) {
    top: -415px;
    right: -480px;
}

.Home-4 .icons .integrationbox:nth-child(5) {
    top: -280px;
    right: -590px;
}

.Home-4 .icons .integrationbox:nth-child(6) {
    top: -150px;
    right: -480px;
}

@media (min-width: 300px) and (max-width: 500px) {
    .Home-4 {
        padding-top: 20%;
        height: 244vw;
    }

    .Home-4 .border {
        display: flex;
        justify-content: center;
        width: 80%;
        border: none;
        margin: 5% 5%;
    }
    .Home-4 .border .middle strong {
        display: flex;
        width: 95%;
        color: #000;
        z-index: 2;
        font-size: 30px;
        letter-spacing: -2px;
        line-height: 60px;
        font-weight: lighter;
        margin-bottom: 4%;
    }

    .Home-4 .icons {
        position: static;
        display: grid;
        grid-template-columns: auto auto;
        gap: 15%;
    }

    .Home-4 .icons .integrationbox {
        position: static;
        width: 100px;
        height: 100px;
        font-size: 50px;
        text-align: center;
        line-height: 100px;
        background: #fff;
        color: #ffffff;
        z-index: 1;
        border-radius: 0%;
        border: 1px solid #3e3e3e;
    }
}
@media (min-width: 901px) and (max-width: 1200px){
    .Home-4 .icons .integrationbox:nth-child(1) {
        top: -433px;
        left: -310px;
    }
    
    .Home-4 .icons .integrationbox:nth-child(2) {
        top: -280px;
        left: -461px;
    }
    
    .Home-4 .icons .integrationbox:nth-child(3) {
        top: -123px;
        left: -310px;
    }
    
    .Home-4 .icons .integrationbox:nth-child(4) {
        top: -433px;
        right: -346px;
    }
    
    .Home-4 .icons .integrationbox:nth-child(5) {
        top: -280px;
        right: -457px;
    }
    
    .Home-4 .icons .integrationbox:nth-child(6) {
        top: -123px;
        right: -346px;
    }
    
}
@media (min-width: 501px) and (max-width: 900px) {
    .Home-4 {
        padding-top: 0%;
        height: 106vw;
    }

    .Home-4 .border {
        display: flex;
        justify-content: center;
        width: 80%;
        border: none;
        margin: 5% 5%;
    }
    .Home-4 .border .middle strong {
        display: flex;
        width: 95%;
        color: #000;
        z-index: 2;
        font-size: 30px;
        letter-spacing: -2px;
        line-height: 60px;
        font-weight: lighter;
        margin-bottom: 4%;
    }

    .Home-4 .icons {
        position: static;
        display: grid;
        grid-template-columns: auto auto;
        gap: 15%;
    }

    .Home-4 .icons .integrationbox {
        position: static;
        width: 100px;
        height: 100px;
        font-size: 50px;
        text-align: center;
        line-height: 100px;
        background: #fff;
        color: #ffffff;
        z-index: 1;
        border-radius: 0%;
        border: 1px solid #3e3e3e;
    }
}