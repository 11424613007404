nav {
  background-color: #25285f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
}
.menuicon{
  display: none;
}

.logo img {
  width: 250px;
}
.menu {
  list-style-type: none;
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  align-items: center;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding-inline-start: 0px !important;
}
.menu-1{
  display: none;
}
.menu li {
  margin: 0 10px;
  font-size: 15px;
}

.menu li a {
  text-decoration: none;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin:0;
  padding: 17px 0px;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.2);
}
.menu li a:hover svg {
  transform: rotate(180deg);
  transition: transform 0.5s ease; 
}
/* ...existing styles... */

.dropdown-menu {
  z-index: 1000;
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: auto;
  align-items: center;
  position: absolute;
  background-color: #ffffff;
  display: none;
  box-shadow: 0 5px 50px 0 rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  top: 100%; 
}
.dropdown-menu li {
  margin: 0;
  font-size: 13px;
  text-align: left;
  text-transform: capitalize;
  border-bottom: 1px solid #f5f5f5;
}
.dropdown-menu li:hover{
  color: #25285f;
  border-bottom: 1px solid #d64949;
}
.dropdown-menu li a {
  text-decoration: none;
  color: #333333;
  display: block;
  padding: 20px 20px;
}
.dropdown-menu li a:hover{
  color: #25285f;
}
.menu li:hover .dropdown-menu {
  display: block;
  transition: all 1s ease-in-out;
}



/* Media queries for responsive design */

@media (min-width: 925px) and (max-width: 1128px){
  nav {
    background-color: #25285f;
    display: flex;
    align-items: center;
    padding: 0px 0px;
  }
  .menu li {
    margin: 0 5px;
    font-size: 15px;
  }
}

@media (max-width: 924px) {
  nav {
    justify-content: flex-start;
  }
  .menuicon{
    display: flex;
    margin-right: 30%;
    color: #fff;
    font-size: xx-large;
  }
  .logo img {
    width: 200px;
    margin: 10% 10%;
  }
  .menu {
    display: none; /* Hide the menu on small screens */
  }
}

@media (max-width: 430px) {
  .menu {
    display: none; /* Hide the menu on small screens */
  }
  nav{
    justify-content: flex-start;
  }
  .logo img {
    width: 200px;
    margin: 10% -19%;
  }
}
