.footermain {
    width: 100%;
    height: max-content;
    background: #f8f9fa;
    justify-content: center;
}

.footermain .footermain-1 {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2% 0%;
    flex-wrap: wrap;
    color: #2a2d63;
}

.footermain-1 .reveation {
    display: flex;
    flex-direction: column;
    width: 22%;
    align-items: center;
    color: #25285f;
    gap: 20px;
}

.reveation img {
    width: 230px;
    padding-top: 7%;
}

.reveation p {
    text-transform: none;
    font-weight: 500;
    line-height: 32px;
    margin: 0% 9%;
    padding-left: 2.4%;
    padding-right: 2%;
    font-size: 15px;
}

.footermain-1 .customapplication {
    width: 22%;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.customapplication p {
    text-transform: capitalize;
    font-size: 24px;
    letter-spacing: 1px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 5);
}

.customapplication ul li a span {
    display: inline-flex;
    align-items: center;
}

.customapplication ul li a svg {
    margin-top: 6px;
}

.customapplication ul {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding-left: 3.5%;
}

.customapplication ul li {
    list-style-type: none;
    margin: 10px;
}

.customapplication ul li a {
    display: inline-flex;
    text-align: left;
    text-decoration: none;
    color: #25285f;
    font-size: 15px;
    letter-spacing: 0.5px;
    line-height: 33px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
}

.customapplication ul li a:hover span {
    color: #e83e8c;
    transition: all 1s ease-in-out;
}

.footermain-1 .contactinfo {
    width: 22%;
    align-items: left;
    display: flex;
    flex-direction: column;
    padding-top: 1.8%;
}

.contactinfo strong {
    text-transform: capitalize;
    font-size: 24px;
    letter-spacing: 1px;
    font-weight: 400;
    text-shadow: 0 0 1px rgba(0, 0, 0, 5);
}

.contactinfo .address-1 {
    display: flex;
    flex-direction: column;
    padding-top: 7.8%;
}

.address-1 .Address {
    display: flex;
    flex-direction: row;
    padding-top: 14px;
}

.Address .logo {
    border: 1px solid;
    border-radius: 50%;
    padding: 41% 44%;
    font-size: xx-large;
}

.Address .addrewrite {
    display: flex;
    flex-direction: column;
    padding-left: 16%;
    padding-top: 3%;
}

.addrewrite p {
    font-size: 15px;
    font-weight: 400;
    line-height: 30px;
}

.addrewrite a {
    text-decoration: none;
    color: #25285f;
    font-size: 15px;
    font-weight: 400;
    line-height: 15px;
}

.footermain-1 .contactinfo {
    width: 22%;
}

/*********/

.footermain .footermain-2 {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: row;
    padding: 1% 0%;
    flex-wrap: wrap;
    color: #2a2d63;
    font-size: 14px;
    letter-spacing: 1px;
    align-items: center;
    justify-content: space-between;
}

.footermain-2 p {
    padding-left: 8.5%;
}

.footermain-2 .Last {
    padding-right: 12%;
    font-weight: 600;
}

.footermain-2 .Last b {
    text-decoration: none;
    color: #151853;
    font-size: 15px;
    font-weight: 525;
    line-height: 1px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 1);
}



/* Media Queries for Responsive Styling */

@media (min-width: 30px) and (max-width: 599px) {
    .footermain .footermain-1 .reveation,
    .footermain .footermain-1 .customapplication,
    .footermain .footermain-1 .contactinfo {
        width: 100%; /* Set width to 100% on smaller screens */
        padding-left: 10%;
    }
    .reveation img {
        width: 250px;
        padding-top: 7%;
    }
    .footermain-1 .customapplication {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
    .customapplication ul {
        padding-left: 0%;
    }
}

@media (min-width: 600px) and (max-width: 899px) {
    .footermain .footermain-1 .reveation,
    .footermain .footermain-1 .customapplication,
    .footermain .footermain-1 .contactinfo {
        width: 50%; /* Set width to 100% on smaller screens */
        align-items: center;
    }
    .reveation img {
        width: 250px;
        padding-top: 7%;
    }
    .footermain-1 .customapplication {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
    }
    .customapplication ul {
        padding-left: 0%;
    }
}

@media (min-width: 900px) and (max-width: 1300px){
    .footermain .footermain-1 .reveation,
    .footermain .footermain-1 .customapplication,
    .footermain .footermain-1 .contactinfo {
        width: 25%; /* Set width to 50% on smaller screens */
    }
    .reveation img {
        width: 200px;
        padding-top: 7%;
    }
    .footermain-1 .customapplication {
        width: 50%;
    }
    .footermain .footermain-2 {
        justify-content: center;
        padding: 0;
    }
    .footermain-2 .Last {
        padding-right: 7%;
        font-weight: 600;
    }
    .customapplication p{
        font-size: 20px;
    }
    .contactinfo p{
        font-size: 20px;
    }
    .addrewrite p {
        font-size: 15px;
        font-weight: 400;
        line-height: 30px;
    }
}
