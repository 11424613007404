// @import url("https://fonts.googleapis.com/css2?family=Nunito:wght@700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Nunitol:wght@300&display=swap");
.container {
    margin-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 991px) {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .section1 {
    width: 50%;
    margin-left: 10%;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 991px) {
      width: 100%;
      margin-left: 0%;
    }

    .heading{
        font-size: 2.25rem;
        color: #25285f;
        font-weight: 1000;

  font-family: "Nunito", sans-serif;
  @media screen and (max-width: 991px) {
    margin-top: 5vh;
  }
  
    }
    .text{
        padding-right: 10px;
        width: 100%;
        margin-top: 3vh;
        font-size: 1rem;
        font-family: "Nunitol", sans-serif;
        color: #666666;
        letter-spacing: 1px;
        line-height: 1.6;
        @media screen and (max-width: 991px) {
            width: 80%;
            margin-left: 10%;
          }
    }
  }
  .section2 {
    width: 30%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 991px) {
      width: 100%;
      margin-right: 0%;
      margin-top: 5vh;
    }
    .img1{
        width: 100%;
    }
  }
}

.container2{
    margin-top: 15vh;
    display: flex;
    @media screen and (max-width: 991px) {
        display: flex;
        flex-direction: column-reverse;
      }

    
    .section3{
        width: 40%;
        margin-left: 10%;
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-left: 0%;
          }

          .img2{
            width: 100%;
          }

    }

    .section4{
        width: 40%;
        margin-right: 10%;
        align-items: center;
        justify-content: center;
        text-align: left;
        @media screen and (max-width: 991px) {
            width: 90%;
            margin-left: 5%;
          }

          .heading2{
            font-size: 2.5rem;
            font-weight: 1000;
          }
          .text2{
            margin-top: 3vh;
            font-size: 0.8rem;
            font-family: "Nunitol", sans-serif;
            color: #666666;
            letter-spacing: 1px;
            word-spacing: 1px;
            line-height: 1.6;
          }
          .button{
            color: white;
            background: #25285f;
            padding: 10px 30px;
            border-radius: 3px;
            margin-top: 25px;
            cursor: pointer;
          }


          
    }

}

.container3{
  margin-top: 15vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;

  .section5{
    text-align: center;justify-content: center;align-items: center;
    width: 100%;
    .heading3{
      text-align: center;
      font-size: 3rem;
      @media screen and (max-width: 991px) {
font-size: 2rem;      }
    }
    
    .divider{
      background: #25285f;
      height: 2px;
      width: 40px;
      margin-top: 2vh;
      margin-left: 48%;
      @media screen and (max-width: 991px) {
        width:10%;
        margin-left: 44%;
      }
    }
   
    
  }
  .text3{
    margin-top: 3vh;
    font-size: 1rem;
    font-family: "Nunitol", sans-serif;
    color: #666666;
    letter-spacing: 1px;
    word-spacing: 1px;
    line-height: 1.6;
    width: 55%;
    margin-left: 22.5%;
    @media screen and (max-width: 991px) {
      width: 80%;
      margin-left: 10%;

    }
  }

}

p{
  margin-top: 3vh;
    font-size: 1rem;
    font-family: "Nunitol", sans-serif;
    color: #666666;
    font-weight: lighter;
}

.section6{
  margin-bottom: 10vh;
}