@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300&display=swap');

.container {
    margin-top: 2vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 991px) {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .section1 {
    width: 50%;
    margin-left: 10%;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 991px) {
      width: 100%;
      margin-left: 0%;
    }

    .heading{
        margin-left: 15%;
        font-weight: 400;
        width: 70%;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 2.25rem;
        color: #25285f;
  font-family: "Nunito", sans-serif;
  @media screen and (max-width: 991px) {
    margin-top: 5vh;
  }
  
    }
    .text{
        padding-right: 10px;
        width: 100%;
        margin-top: 3vh;
        font-size: 1rem;
        font-family: "Nunito", sans-serif;
        color: #666666;
        letter-spacing: 1px;
        line-height: 1.6;
        @media screen and (max-width: 991px) {
            width: 80%;
            margin-left: 10%;
          }
    }
    .button{
        color: white;
        background: #25285f;
        padding: 10px 30px;
        border-radius: 3px;
        margin-top: 25px;
        cursor: pointer;
      }
  }
  .section2 {
    width: 30%;
    margin-right: 10%;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 991px) {
      width: 100%;
      margin-right: 0%;
      margin-top: 5vh;
    }
    .img1{
        width: 100%;
    }
  }
}

.container2 {
    margin-top: 15vh;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 991px) {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .section3 {
    width: 100%;
    text-align: center;
    justify-content: center;

    @media screen and (max-width: 991px) {
      width: 100%;
      margin-left: 0%;
    }

    .heading{
        margin-left: 15%;
        font-weight: 1000;
        width: 70%;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-size: 2.25rem;
        color: black;
  font-family: "Nunito", sans-serif;
  @media screen and (max-width: 991px) {
    margin-top: 5vh;
  }
  
    }
    .text{
        margin-left: 17.5%;
        padding-right: 10px;
        width: 65%;
        margin-top: 3vh;
        font-size: 1rem;
        font-family: "Nunito", sans-serif;
        color: #666666;
        letter-spacing: 1px;
        line-height: 1.6;
        @media screen and (max-width: 991px) {
            width: 80%;
            margin-left: 10%;
          }
    }
    .divider{
        background: #25285f;
        height: 2px;
        width: 40px;
        margin-top: 2vh;
        margin-left: 48%;
        @media screen and (max-width: 991px) {
          width:10%;
          margin-left: 44%;
        }
      }
    
  
  
  }
}

.container4{
  margin-top: 10vh;
    margin-bottom: 10vh;
    width: 100%;
    display: flex;
    @media screen and (max-width: 991px) {
        width:100%;
        display: flex;
        flex-direction: column;
      }
    .section1{
        width: 40%;
        margin-left: 10%;
        @media screen and (max-width: 991px) {
            width:100%;
            margin-left: 0%;
          }
        .img{
            width: 100%;
            margin-top: 50%;
        }
    }
    .section2{
        width: 40%;
        margin-right: 10%;
        @media screen and (max-width: 991px) {
            width: 80%;
            margin-right: 0;
            margin-left: 10%;
          }
        .heading{
            margin-left: 30%;
            font-weight: 1000;
            font-size: 2.25rem;
            color: black;
      font-family: "Nunito", sans-serif;
      @media screen and (max-width: 991px) {
        margin-top: 5vh;
        margin-left: 10%;
      }}
      .card{
        margin-top: 5vh;
        .h5{
            margin-top: 0px;
            font-size: 1.25rem;        }
      }

      
    }
}

.container3{
  width: 90%;
  margin-left: 10%;
  background: #25285f;
  @media screen and (max-width: 991px) {
    width: 100%;
    margin-left: 0%;

  }
  .container{
    margin-top: 5vh;
    width: 100%;
    display: flex;
    @media screen and (max-width: 991px) {
        width: 100%;
        margin-left: 0%;
        display: flex;
        flex-direction: column;  
       
      }
    .section11{
      padding-top: 15vh;
      padding-bottom: 5vh;
        width: 40%;
        text-align-last: left;
        @media screen and (max-width: 991px) {
            width: 80%;
            margin-left: 10%;
      
          }

        .title{
            font-weight: bolder;
            font-size: 1.25rem;
            color: white;
        }
        .heading{
          text-align: left;
            margin-top: 2vh;
            font-size: 2.5rem;
            color: white;
            font-family: 'Nunito', sans-serif;
            font-weight: 1000;

        }
        .text{
            margin-top: 3vh;
            line-height: 1.5;
            color: white;
            word-spacing: 1px;
            font-weight: lighter;
            font-family: 'Nunito', sans-serif;
        }
    }
    .section2{
        width: 40%;
        margin-right: 0%;
        margin-bottom: 10vh;
        @media screen and (max-width: 991px) {
            width: 100%;
            margin-left: 0%;
            margin-top: 10vh;
            margin-bottom: 5vh;
      
          }
        .img{
            width: 80%;
            @media screen and (max-width: 991px) {
                width: 100%;
        
              }
        }
    }
}
}


.container12{
  width: 95%;
  margin-right: 5%;
  background: #F9F9F9;
  @media screen and (max-width: 991px) {
    width: 100%;
    margin-left: 0%;

  }
.container{
  padding-top: 5vh;
  padding-bottom: 5vh;
  margin-top: 5vh;
  width: 100%;
  display: flex;
  @media screen and (max-width: 991px) {
      width: 100%;
      margin-right: 0%;
      display: flex;
      flex-direction: column;  
     
    }
  .section2{
    padding-top: 5vh;
    padding-bottom: 5vh;
      margin-left: 5%;
      width: 40%;
      text-align-last: left;
      @media screen and (max-width: 991px) {
          width: 80%;
          margin-left: 10%;
    
        }

      .title{
          font-weight: bolder;
          font-size: 1.25rem;
          color: #25285f;
      }
      .heading{
          margin-top: 2vh;
          font-size: 2.5rem;
          color: black;
          font-family: 'Nunito', sans-serif;
          font-weight: 1000;

      }
      .text{
          margin-top: 3vh;
          line-height: 1.5;
          color: #666666;
          word-spacing: 1px;
          font-weight: lighter;
          font-family: 'Nunito', sans-serif;
      }
  }
  .section1{
      width: 40%;
      margin-right: 0%;
      margin-bottom: 10vh;
      @media screen and (max-width: 991px) {
          width: 100%;
          margin-left: 0%;
          margin-top: 10vh;
          margin-bottom: 5vh;
    
        }
      .img{
          width: 100%;
          @media screen and (max-width: 991px) {
              width: 100%;
      
            }
      }
  }
}
}