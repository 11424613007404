.container{
width: 80%;
margin-left: 10%;
margin-right: 10%;
text-align: justify;

.heading{
    font-size: 3rem;
    font-family: "Nunito", sans-serif;
    font-weight: 1000;
    color: #25258f;
    margin-top: 10vh;
    @media screen and (max-width: 991px) {
        margin-top: 15vh;
      }

}

    img{
        width: 60%;
    }
}


.container3{
    width: 80%;
    display: flex;
    margin-left: 10%;
    padding-top: 5vh;
    padding-bottom: 5vh;
    @media screen and (max-width: 991px) {
        margin-left: 5%;
        display: flex;
        flex-direction: column;
      }
    .section1{
        width: 50%;
        @media screen and (max-width: 991px) {
            width: 100%;
          }
        img{
            width: 100%;
        }
        
    }
    .section2{
        width: 50%;
        @media screen and (max-width: 991px) {
            width: 110%;
          }

        .h1{
            font-size: 1.5rem;
            font-weight: 1000;
            font-family: 'Trebuchet MS', sans-serif;
            text-align: justify;
        }

        .para{
             font-size: 1rem;
             font-family: "Nunito", sans-serif;
             font-weight: 400;
             text-align: justify;

        }
    }
}